import React, { useEffect } from "react";

const appScheme = "alfred-app://";

const DeepLink = () => {
  useEffect(() => {
    const deepLinkPath = window.location.search.replace("?linkTo=", "");
    window.location.replace(`${appScheme}${deepLinkPath}`);
  }, []);

  return <div>Install Alfred app and create an account to use this link</div>;
};

export default DeepLink;
